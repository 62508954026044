<template>
<section id="station-info">
  <div v-if="station.mode==='offline'">
    <h2>Station not online</h2>
    <p>try again later</p>
  </div>
  <div v-else>
    <h1 class="title">
      {{ station.name }}
    </h1>
    <h2 class="subtitle">
      {{ station.description }}
    </h2>
    <p>{{ station.listeners }} listening</p>
  </div>
</section>
</template>

<script>
export default {
  name: "StationInfo",
  inject: ['station'],
};
</script>

<style >
#station-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 524px;
  width: 100%;
  background-color: #0707647F;
}
.title
{
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
  display: block;
  font-weight: 300;
  font-size: 28px;
  letter-spacing: 1px;
}
.subtitle
{
  font-weight: 300;
  font-size: 20px;
  word-spacing: 4px;
  padding-bottom: 0px;
}
.navigation-items {
  display: none;
}

@media (min-width: 704px) {
  .navigation-items {
    display: block;
  }
}

</style>
