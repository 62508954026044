<template>
<img id="songimage" :src="bgImage" :width="width" :height="height" />

</template>

<script>

export default {
  data() {
    return {
      width: 375,
      height: 558,
     // bgImage: '/images/danelectrolefty0-2021.jpg',
      bgImage: '/images/Boston_sunset-720w-0780.jpg',
    }
  },
}
</script>

<style scoped>
#songimage {
    position: relative;
    top: -70px;
    width: 375px;
    height: 558px;
    margin: 0 auto;
}

</style>
