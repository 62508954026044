<template>
<div class="basecard">
    <slot name="heading"></slot>
    <slot></slot>
    <slot name="footing"></slot>
</div>
</template>

<style scoped>
.basecard {
  z-index: 1;
  position: absolute;
  top: 114px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 0 auto;
  padding: 0;
  width: 100%;
  background-color: #C4C4FFC0;
  color: #443406;
}
.basecard h1 {
  background-color: #C4C4FF;
}
@media (min-width: 704px) {
  .basecard {
    margin-left: 20%;
    max-width: 60%;
  }
}
</style>
