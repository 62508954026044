<template>
<TheHeader
  :selected-view="selectedView"
  @toggle-sidenav="displaySidenav = !displaySidenav"
  />
<TheSidenav
  :show="displaySidenav"
  :selected-view="selectedView"
  @close="displaySidenav = false"
  />

<main>
  <div v-if="isLoading"><base-spinner></base-spinner></div>
<ThePlayer v-else-if="station.mode!=='offline'"
  :stream-url="streamUrl"
  @toggle-on="toggleListening"/>

<component
  v-if="selectedView !== 'neither'"
  :is="selectedView"
  v-bind="viewProps"></component>
  
<StationInfo />

</main>
</template>

<script>
import TheHeader from './components/layouts/TheHeader.vue'
import TheSidenav from './components/layouts/TheSidenav.vue'
import ThePlayer from './components/ThePlayer.vue'
import StationInfo from './components/StationInfo.vue'
import TheChat from './components/TheChat.vue'
import PlayLog from './components/PlayLog.vue'

const streamRe = /(\w+)-(play|live)\.mp3/
function grepStation(listenurl) {
  const segments = listenurl.split('/')
  const path = segments[segments.length-1]
  const isStream = path.match(streamRe)
  if (!isStream) {
    return {handle: '', mode: 'error'}
  }
  return {handle: isStream[1], mode: isStream[2]}
}

export default {
  components: {
    TheHeader,
    TheSidenav,
    ThePlayer,
    StationInfo,
    PlayLog,
    TheChat,
  },
  data() {
    return {
      selectedView: 'neither',
      displaySidenav: false,
      listening: false, 
      chatName: 'Anonmouse',
      chatTicket: '',
      conn: null,
      isLoading: false,
      //staListenurl: 'listenurl',
      staInfoUrl: 'server_url',
      sources:[],
      station: {
        name: 'loading... _name',
        description: 'loading... _description',
        handle: 'larrybillson',
        title: '[ loading... ]',
        listeners: 0,
        mode: '',
      },
      playlog: [],
      chatlog: [],
    }
  },
  provide() {
    return {
      station: this.station,
      setChatName: this.updateChatName,
      scrollChatLog: this.scrollChatLog,
      updateChatTicket: this.updateChatTicket, 
      chatLog: this.chatlog,
      chatTicket: this.chatTicket,
      selectView: this.selectView,
    }
  },
  computed: {
    streamUrl() {
      return `${this.streamHost}/${this.station.handle}.mp3`
    },
    wsServer() {
      return this.npHost.replace('http','ws') + "/subscribe/" + this.station.handle
    },
    viewProps() {
      if (this.selectedView === 'TheChat') {
        return {chatName: this.chatName}
      } else if (this.selectedView === 'PlayLog') {
        return {playLog: this.playlog}
      }
      return {}
    },
  },
  watch: {
    wsServer(val) {
      if (!val.endsWith('undefined')) {
        this.setupWebSocket()
      }
    },
  },
  methods: {
    selectView(view) {
      if (view === 'chat') {
        this.selectedView = 'TheChat'
      }
      else if (view === 'playlog') {
        this.selectedView = 'PlayLog'
      }
      else {
        this.selectedView = 'neither'
      }
    },
    toggleListening() {
      this.listening = !this.listening
      if (this.listening) {
        this.station.listeners += 1
      } else {
        this.station.listeners -= 1
      }
    },
    updateNowPlaying(status) {
      // status [nanosec, mode, lcount, title]
      status[0] = this.getTLabel(status[0])
      if (!this.playlog[0] || status[0]!==this.playlog[0][0] || status[3]!==this.playlog[0][3]) {
        // should check title too since only 1 min resolution here
        //not already logged
        // if (this.playlog[0]) {
        //   console.log('status', status[0], this.playlog[0][0])
        // }
        this.playlog.unshift(status) // Adds to the beginning of playlog
        // TODO config file for this playlog.length?
        if (this.playlog.length > 10) {
          this.playlog.pop()
        }
        
        // only update fields that have data
        if (status[1]!==this.station.mode) {
          //console.log(`Mode change ${this.station.mode} ${status[1]}`)
        }
        if (status[1]!=="") {
          this.station.mode = status[1]
        }
        if (status[2]!=="") {
          this.station.listeners = +status[2]
        }
        if (status[3]!=="") {
          this.station.title = status[3]
        }
        
      }
    },
    updateChatTicket(newTicket) {
      //console.log('UPDATING TICKET')
      this.chatTicket = newTicket
      //console.log("t:", this.chatTicket)
      localStorage.setItem('chatTicket', this.chatTicket)
    },
    updateChat(msg) {
      //console.log('got chat-update msg:', msg)
      if (!msg[2]) {
        msg[2] = 'anon'
      } else if (!msg[1]) {
        msg[1] = 'anon'
      }
      const role = msg[1]
      const chatter = msg[2]
      this.chatlog.push({
        tstamp: msg[0]/1000000, // nanosecs (go) to millisecs (js)
        tlabel: this.getTLabel(msg[0]),
        role: role.trim(),
        chatter: chatter.trim(),
        text: msg[3].trim(),
      })
      this.scrollChatLog()
    },
    getTLabel(nanosec) {
      const tfcfg = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }
      if (nanosec===0) {  // 0 is for now()
        return new Date().toLocaleTimeString('default', tfcfg)
      } else {
        const tstamp = nanosec/1000000 // nanosecs (go) to millisecs (js)
        return new Date(tstamp).toLocaleTimeString('default', tfcfg)
      }
    },
    scrollChatLog() {
      this.freshenChatLog()
      this.$nextTick(function() { // wait for DOM to be updated
        const mlog = document.getElementById('chat-log')
        if (mlog!==null) {
          const logVisualHeight = mlog.clientHeight
          const logTotalHeight = mlog.scrollHeight
          mlog.scrollTo(0, logTotalHeight - logVisualHeight) 
        }
      })
    },
    freshenChatLog() {
      // remove over 12 hours old 86400000/2
      const nowstamp = Date.now()
      const logLength = this.chatlog.length
      let oldi = 0
      for ( let i = 0; i < logLength; i++ ) {
        if ( nowstamp - this.chatlog[i].tstamp < 86400000/2 ) {
          break
        }
        oldi++
      }
      for (let i = 0; i < oldi; i++) {
        this.chatlog.shift()
      }
    },
    updateChatName(name) {
      this.chatName = name
      localStorage.setItem('chatName', this.chatName)
    },
    setStation() {
      //console.log("location: ", location)
      //console.log('setting the station')
      // 'source' is: undefined, a source object, or an array of source objects
      const sources = this.sources
      const curTitle = this.station.title
      const stations = []
      const sta = {}
      for (const src of sources) {
        sta.listenurl = src.listenurl
        const modeHandle = grepStation(sta.listenurl)
        sta.mode = modeHandle.mode
        sta.handle = modeHandle.handle
        sta.name = src.server_name
        sta.description = src.server_description
        sta.infoUrl = src.server_url
        sta.title = src.title
        sta.listeners = src.listeners
        if (sta.handle === this.station.handle) {
          if (sta.mode === 'live') {
            // priority goes first
            stations.unshift({...sta})
          } else if (sta.mode === 'play') { // playlist
            // put at end
            stations.push({...sta})
          } // else ? relay?
        } 
      }
      //console.log('stations', stations)
      if (stations.length>0) {
        //this.staListenurl = stations[0].listenurl
        this.station.name = stations[0].name
        this.station.handle = stations[0].handle
        this.station.description = stations[0].description
        this.staInfoUrl = stations[0].infoUrl
        //this.station.title = stations[0].title
        //this.station.listeners = stations[0].listeners
        //this.station.mode = stations[0].mode
        if (this.station.title===undefined) {
          this.station.title = curTitle
        }
        // ts, mode, lcount, title
        this.updateNowPlaying([0, stations[0].mode, stations[0].listeners, stations[0].title]) // just to log time
      } else {
         //console.log('no stations')
      }
    },
    async updateSources() {
      this.isLoading = true
      //fetch('https://camp.buskers.org/status-json.xsl').then(
       await fetch(`${this.statsHost}/status-json.xsl`).then(
         function(res) {
           if (res.ok) {
             return res.json()
           } else {
             throw new Error('could not fetch audio server status')
           }
         }
       ).then( data => {
         const { source } = data.icestats
         if (typeof(source)!='object') {
           this.sources = []
         } else if (source.length) {  //it's an array sources
           const sources = source.filter(
             // this condition happens on inactive aliases
             src => src.stream_start_iso8601 != undefined
           )
           this.sources = sources
         } else {
           // it's not an array, but we always store an array
           // so we don't have to test for it when unpacking it
           this.sources = [source,]
         }
         this.setStation()
       }).catch(function(e) {
// eslint-disable-next-line
         console.log('opps:', e)
         // TODO handle these errors better
         // example: go server not up:
         // TypeError: NetworkError when attempting to fetch resource.
       })
      this.isLoading = false
    },
    switchMode(newMode) {
      this.station.mode = newMode
    },
    setupWebSocket() {
      if (this.conn) {
        this.conn.close(1000);
        //console.log('Closed old Connection in setupWebSocket');
      }
      this.conn = new WebSocket(this.wsServer);
      //console.log(`websocket connecting to ${this.wsServer}`);
      this.conn.onopen = () => {
        //console.log('websocket ready')
      }
      this.conn.onmessage = (msg) => {
        //console.log('processing msg:', msg)
        const data = JSON.parse(msg.data);
        if ( data.MsgType==="chatdata" ) {
          //console.log('got chat', data.Message)
          this.updateChat([data.Tstamp, ...data.Message])
        } else if (data.MsgType==="setdata") {
          //console.log('got setdata',data.Message)
          this.updateNowPlaying([data.Tstamp, ...data.Message])
        } else if ( data.MsgType==="logdata") {
          //console.log('DATAMSG', data.Message)
          if (this.station.mode === data.Message[0]) {
            if ( this.station.mode==="live" && data.Message[2]==="ending live stream") {
              // [nanosec, mode, lcount, title]
              this.updateNowPlaying([data.Tstamp,
                                     "play",
                                     data.Message[1],
                                     data.Message[2]])
            } else {
              this.updateNowPlaying([data.Tstamp, ...data.Message])
            }
          } else if (data.Message[0]==="live" && data.Message[2]!=="ending live stream" ) { // and mode !== "live"
          this.updateNowPlaying([data.Tstamp,
                                 "live",
                                 data.Message[1],
                                 data.Message[2]])
          } else { //
            // for when the playlist stream updates during a live stream.
            //console.log(`skipping msg for mode: ${data.Message[0]}`);
          }
        } else {
          //console.log(`skipping msg for -- MsgType: ${data.MsgType}, mode: ${data.Message[0]}`);
        }
      }
      this.conn.onclose = () => {
        // TODO handle this better with setTimeout retry?
        const closeTime =  new Date().toLocaleTimeString()
        // eslint-disable-next-line        
        console.log("WebSocket closed", closeTime);
      }
      this.conn.onerror = function(e) {
        // TODO handle error with setTimeout retry?
        // eslint-disable-next-line
        console.error("WebSocket error in setup:", e);
      }
    },
  },
  mounted() {
    this.updateSources()
    this.setupWebSocket()
    this.chatName = (localStorage.getItem('chatName')) ? localStorage.getItem('chatName') : this.chatName
    this.chatTicket = localStorage.getItem('chatTicket')
  },
  unmounted() {
    this.conn.close(1000);
  },
}

</script>

<style>
@font-face{
    font-family:Material-Design-Iconic-Font;
    src:url(/fonts/2.2.0/Material-Design-Iconic-Font.woff2) format('woff2'),url(/fonts/2.2.0/Material-Design-Iconic-Font.woff) format('woff'),url(/fonts/2.2.0/Material-Design-Iconic-Font.ttf) format('truetype')
}
html {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: #eaab00;
  background-color: #070764;
}
main {
  text-align: center;
  min-height: 96vh;
}
*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
}
::selection {
    background: #b3d4fc;
    text-shadow: none;
}
.drawer-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 40px;
  width: 35px;
  cursor: pointer;
  position: absolute;
  left: 5px;
}
@media (min-width: 704px) {
  .drawer-toggle {
    display: none;
  }
  .results {
      margin-top: 1em;
  }
}
.drawer-toggle .bar {
  width: 85%;
  height: 2px;
  background-color: white;
}

</style>
