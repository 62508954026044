<template>
<header >
  <TheSideNavToggle @toggle="$emit('toggle-sidenav')" />
  <nav class="main-nav">
    <ul class="nav-items"> 
      <li class="nav-item">
        <button
          id="player"
          :class="{'active': selectedView==='neither'}"
          @click="selectView('player')">
          Player</button>
      </li>
      <li class="nav-item">
        <button
          id="chat"
          :class="{'active': selectedView==='TheChat'}"
          @click="selectView('chat')">
          Chat</button>
      </li>
      <li class="nav-item">
        <button
          id="log"
          :class="{'active': selectedView==='PlayLog'}"
          @click="selectView('playlog')">
          PlayLog</button>
      </li>
    </ul>
  </nav>
</header>
</template>

<script>
import TheSideNavToggle from './TheSideNavToggle.vue'
//import TheSettings from '../TheSettings.vue'

export default {
  name: "TheHeader",
  inject: ['selectView'],
  props: ['selectedView'],
  components: {
    TheSideNavToggle,
//    TheSettings,
  },
};
</script>

<style scoped>
header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 2.8rem;
  background-color: #11005c;
}
.title
{
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
  display: block;
  font-weight: 300;
  font-size: 32px;
  letter-spacing: 1px;
}
.subtitle
{
  font-weight: 300;
  font-size: 22px;
  word-spacing: 4px;
  padding-bottom: 0px;
}


.main-nav {
  display: none;
}

@media (min-width: 704px) {
  .main-nav {
      display: block;
  }
}

nav {
  height: 100%;
}

ul.nav-items {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item button {
  text-decoration: none;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  padding: 0.5rem 1.5rem;
  display: inline-block;
}

.nav-item button:hover,
.nav-item button:active,
.nav-item button.active {
  color: #f1a80a;
  border-color: #f1a80a;
  background-color: #1a037e;
}


</style>
