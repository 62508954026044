import { createApp } from 'vue'
import App from './App.vue'
import BaseCard from './components/BaseCard.vue'
import BaseButton from './components/BaseButton.vue'
import BaseDialog from './components/BaseDialog.vue'
import BaseInput from './components/BaseInput.vue'
import BaseSpinner from './components/BaseSpinner.vue'

import { version, name } from '../package.json'

const app = createApp(App)

app.config.globalProperties.appVersion = version || '0'
app.config.globalProperties.appName = name || 'App'

// http://tunes.larrybillson.com:8033
app.config.globalProperties.streamHost = process.env.VUE_APP_STREAM || 'http://localhost:8033'
// https://camp.buskers.org:8033
app.config.globalProperties.statsHost = process.env.VUE_APP_STATS || 'http://localhost:8033'
// https://tunes.larrybillson.com:8034
app.config.globalProperties.npHost = process.env.VUE_APP_NP || 'http://localhost:8034'
// https://tunes.larrybillson.com:8034
app.config.globalProperties.chatHost = process.env.VUE_APP_CHAT || 'http://localhost:8034'
// app.config.globalProperties.timeOut = process.env.TIMEOUT || 6000

app.component('base-card', BaseCard)
app.component('base-button', BaseButton)
app.component('base-dialog', BaseDialog)
app.component('base-input', BaseInput)
app.component('base-spinner', BaseSpinner)

app.mount('#app')
