<template>
<base-card>
  <template v-slot:heading>
      <h4>Recently Played</h4>
  </template>
  <template v-slot:default>
    <section id="play-log">
      <p> currently <strong>{{ playLog[0][3] }}</strong></p>
      <ol v-if="playLog.length>1">
        <li v-for="loggedItem in playLog.slice(1)" :key="loggedItem">
          {{ loggedItem[0] }} <strong>{{ loggedItem[3] }}</strong></li>
      </ol>
      <p v-else>Nothings been played yet.</p>
    </section>
  </template>
  <template v-slot:footing>
    <p>[v{{ appVersion }}]</p>
  </template>
</base-card>
</template>

<script>
export default {
  props: ['playLog'],
};
</script>

<style scoped>
#play-log {
  text-align: left;
  padding: .5ex;
}
#play-log ul {
    list-style-type: none;
    padding-inline-start: 0px;
    padding: 0;
    margin-top: .5em;
    margin-left: auto;
    margin-right: auto;
    width: 380px;
}
#play-log li {
    margin-top: .5ex;
    margin-bottom: .5ex;
}
#play-log  ol {
    width: 90%;
    list-style-position: inside;
    margin: .5em auto;
    padding: 0;
}
#play-log p {
  width: 90%;
  margin: .5ex auto;
}
</style>
