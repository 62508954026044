<template>
<div id="player">
  <audio id="stream" crossorigin="anonymous" preload="none" :src="streamUrl">
    <source :src="streamUrl" type="audio/mpeg"/>
  </audio>
  <div class="playing">
    <p class="error" v-if="streamMsg">{{ streamMsg }}</p>
    <p v-if="station.title" ><strong>“{{ station.title }}”</strong></p>
  </div>
  <div class="playerButtons">
    <button class="button play"
       @click="toggleAudio"
       :title="startStopText">
      <transition name="slide-fade" mode="out-in">
        <i class="zmdi" :class="startStopIcon" :key="1"></i>
      </transition>
    </button>
  </div>
</div>
<SongImage />
</template>

<script>
import { computed } from 'vue'
import SongImage from './SongImage.vue'

export default {
  name: 'ThePlayer',
  emits: ['toggle-on'],
  props: ['streamUrl'],
  inject: ['station'],
  components: {
    SongImage,
  },
  data() {
    return {
      isPlaying: false,
      streamMsg: "",
      audioEl: null,
      audioCtx: new AudioContext(),
    }
  },
  provide() {
    return {
      audioCtx: this.audioCtx,
      audioEl: computed( () => this.audioEl),
    }
  },
  computed: {
    startStopText() {
      return this.isPlaying ? 'Stop audio stream': 'Start audio stream'
    },
    startStopIcon() {
      return this.isPlaying ? 'zmdi-stop' : 'zmdi-play-circle'
    },
  },
  methods: {
    updateCount() {
      this.$emit('toggle-on')
    },
    toggleAudio() {
      if (!this.isPlaying) {
        this.updateCount()
        this.startAudio();
      } else {
        this.updateCount()
        this.stopAudio();
      }
    },
    initAudio() {
      this.audioEl = document.getElementById("stream")
      const stream = this.audioCtx.createMediaElementSource(this.audioEl)
      stream.connect(this.audioCtx.destination)
      this.audioEl.loop = false;
    },
    startAudio() {
      if (this.audioEl === null) {
        this.initAudio()
      } 
      this.audioEl.src = this.streamUrl
      //this.audioEl.load();      
      if (this.audioCtx.state === 'suspended') {
        this.audioCtx.resume().then( () => {
          //console.log('starting audioContext', this.audioCtx.state)
        })
      }
      this.isPlaying = true;
      this.audioEl.play().then(() => {
        this.streamMsg = "";
      }).catch(error => {
        this.streamMsg = error.message;
        this.stopAudio();
      })  
    },
    stopAudio() {
      //console.log('stopAudio:', this.audioEl)
      //this.audioEl.pause()
      this.isPlaying = false
      // prevent the audio from continuing to download 
      //this.audioEl.src = "";
      this.audioEl.load(); //empty it?
      this.audioCtx.suspend().then( () => {
        this.audioEl.src = null
// eslint-disable-next-line
        console.log('audio suspended')
      })
    },
  },
}
</script>

<style scoped>
.zmdi{
    display:inline-block;
    font:normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size:inherit;
    text-rendering:auto;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
.zmdi-stop:before{content:'\f3b6'}
.zmdi-play-circle:before{content:'\f3a9'}
button {
    background-color: transparent;
    color: #eaab00;
    border: 0;
}
#player {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  background-color: #0707647F;
}
#player p {
    margin: 0 1rem;
    height: 100%;
}
#player .playerButtons .audio-on {
    color: red;
    display: inline;
}
#player p.title, .player p.artist {
    margin: 0;
}
#player .playerButtons .button {
    cursor: pointer;
    font-size: 42px;
}
#player .playmode {
    display: none;
}
#player .playing {
    position: fixed;
    top: 10px;
}

@media (min-width: 704px) {
  #player .playmode {
    display: inline;
  }
  #player .playing {
    position: static;
    display: flex;
  }
}
</style>
