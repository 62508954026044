<template>
<div class="base-input">
  <label v-if="controlType==='input'"><slot />
    <input
      v-bind="$attrs"
      :value="value"
      @input="updateValue" />
  </label>
  <label v-else-if="controlType==='textarea'"><slot />
    <textarea
      v-bind="$attrs"
      :value="value"
      @input="updateValue">
    </textarea>
  </label>
  <label v-else-if="controlType==='radio'">
    <input class="radio" type="radio"
      v-bind="$attrs"
      @input="updateValue" />
    <slot />
  </label>
</div>
</template>

<script>
export default {
  name: 'BaseInput',
  emits: ['update'], //:modelValue',
  props: {
    controlType: {
      type: String,
      default: 'input'
    },
    enteredValue: {
      type: String,
      default: null
    }
  },
  computed: {
    value() {
//      console.log('computing in base-input', this.enteredValue)
      return this.enteredValue
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('update', event.target.value)
    }
  },
}
</script>

<style scoped>
.base-input {
  margin: 10px 0;
}

.base-input label {
  display: block;
  font-weight: bold;
}

.base-input input,
.base-input textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  font: inherit;
  border: 1px solid #ccc;
  padding: 5px;
}

.base-input input:focus,
.base-input textarea:focus {
  background-color: #eee;
  outline: none;
}

.base-input input.radio {
  display: inline;
  width: auto;
  padding: 15px;
  margin: 10px;
}

</style>


