<template>
<base-card>
  <template v-slot:heading>
    <ChatSignIn :current-chatname="chatName"/>
  </template>
  <template v-slot:default>
    <ChatLog />
  </template>
  <template v-slot:footing>
    <ChatSend :chatname="chatName"/>
  </template>
</base-card>
</template>

<script>
import ChatSignIn from './ChatSignIn.vue'
import ChatLog from './ChatLog.vue'
import ChatSend from './ChatSend.vue'

export default {
  name: 'TheChat',
  components: {
    ChatSignIn,
    ChatLog,
    ChatSend,
  },
  props: {
    chatName: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.chat-heading {
  padding: 0 1ex;
  display: flex;
  justify-content: space-between;
}

</style>
