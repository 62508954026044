<template>
<div id="chat-send">
  <form id="chatInput" @submit.prevent="onSend">
    <base-input
      name="message"
      id="message"
      placeholder="...enter your chat message here..."
      autocomplete="off"
      :enteredValue="message"
      @update="checkInput"></base-input>
    <base-button type="submit">Send</base-button>
  </form>
</div>
</template>

<script>
export default {
  inject: ['station', 'chatLog', 'setChatName', 'updateChatTicket'],
  props: ['chatname'],
  data: function() {
    return {
      message: '',
      user: 'anon Anymouse',
    };
  },
  methods: {
    checkInput(val) {
      //console.log(val)
      this.message = val
    },
    onSend(event) {
      //console.log('target', event.target.name.value)
      const post = {
        name: this.chatname,
        message: event.target.message.value,
        ticket: localStorage.getItem('chatTicket'),
      }
      let ticketUpdate = false
      fetch(`${this.chatHost}/chat/${this.station.handle}`, {
        method: "POST",
        body: JSON.stringify(post)
      }).then( r => {
        this.message = ''      
        if (r.status === 200) {
          // got new chat jwt ticket in the body
          ticketUpdate = true
          return r.json()
        } else if (r.status !== 202) {
          throw new Error(`Unexpected HTTP Status ${r.status} ${r.statusText}`)
        }
        return ""
      }).then(json => {
        if (ticketUpdate === true) {
          //console.log("json:", json)
          this.updateChatTicket(json.ticket)
          this.setChatName(json.chatname)
        }
      }).catch(e => {
        const error = true
        const chatter= 'system'
        const text = `Chat failed: ${e.message}`        
        this.appendLog({error, chatter, text,})
      })
    },
    appendLog(msg) {
      msg.tstamp = Number(new Date())
      //msgconst chatter = `${this.prefix} ${this.user}`
      //const text = this.message
      this.chatLog.push(msg)
      this.message = ''
    },
  },
}
</script>

<style scoped>
#chat-send {
}

</style>
