<template>
  <button>
    <slot></slot>
  </button>
</template>

<style scoped>
button {
  padding: 0.4rem .8rem;
  margin: 0.3rem;
  font-family: inherit;
  background-color: #CDCDFF;
  border: 1px solid #FFEE77;
  border-radius: 9px;
  color: #070764;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #B5B5FB;
  border-color: #FFDF00;
  font-weight: bold;
}
.right {
    text-align: right;
}
.flat {
  background-color: transparent;
  color: #3a0061;
  border: none;
}

.flat:hover,
.flat:active {
    background-color: #3a0061;
    color: #edd2ff;
}
.left {
    float: left;
}
.right {
    float: right;
}
.inverted {
  background-color: #edd2ff;
  color: #3a0061;
  border: 1px solid transparent;
}

.inverted:hover,
.inverted:active {
  color: #ccc;
  background-color: white;
  border: 1px solid transparent;
}

.cancel {
  background-color: red;
  color: white;
  border: 1px solid transparent;
}

.cancel:hover,
.cancel:active {
  background-color: salmon;
  border: 1px solid transparent;
  color: white;
}
</style>
