<template>
<div class="sidenav-container">
  <div
    v-if="show"
    class="sidenav-backdrop"
    @click="$emit('close')"></div>
  <transition name="slide-side">
    <div
      v-if="show"
      class="sidenav">
      <ul class="nav-items"
          @click="$emit('close')">
        <li class="nav-item">
          <button
            id="player"
            :class="{'active': selectedView==='Welcome'}"
            @click="selectView('player')">
            Player</button>
        </li>
        <li class="nav-item">
          <button
            id="chat"
            :class="{'active': selectedView==='TheChat'}"
            @click="selectView('chat')">
            Chat</button>
        </li>
        <li class="nav-item">
          <button
            id="log"
            :class="{'active': selectedView==='PlayLog'}"
            @click="selectView('playlog')">
            PlayLog</button>
        </li>
      </ul>  
    </div>
  </transition>
</div>
</template>

<script>
//import IconBase from '../IconBase'
//import IconEqualizer2 from '../icons/Equalizer2'

export default {
  name: "TheSidenav",
//  components: {
//    IconBase,
//    IconEqualizer2,
//  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectedView: {
      type: String,
      default: 'Welcome',
    },
  },
  inject: ['selectView'],
};
</script>

<style scoped>
.sidenav-container {
  height: 100%;
  width: 100%;
}

.sidenav-backdrop {
  width: 100%;
  height: 100%;
  background-color: #11005c70;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
}

.sidenav {
  height: 100%;
  width: 160px;
  background-color: #11005c;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 30px;
  text-align: left;
}

.slide-side-enter-active,
.slide-side-leave-active {
  transition: all 0.3s ease-out;
}
.slide-side-enter,
.slide-side-leave-to {
  transform: translateX(-100%);
}

ul.nav-items {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.nav-item {
  margin-top: 1rem;
}

.nav-item button {
  text-decoration: none;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
}

.nav-item button:hover,
.nav-item button:active,
.nav-item button.active {
  color: #f1a80a;
  border-color: #f1a80a;
  background-color: #1a037e;
}

</style>
