<template>
<section id="chat-log">
  <p v-for="msg in chatLog" :key="msg.text">
    <time :datetime="msg.tlabel">{{ msg.tlabel }}</time>
    <span v-if="msg.role=='creator'" class="role">
      <img src="/images/LBCreator.png" title="creator" alt="creator" />
    </span>
    <span v-else-if="msg.role=='system'" class="role">
      system
    </span>
    <span v-else-if="msg.role=='admin'" class="role">
      admin
    </span>
    <span v-else class="role">
      {{ msg.role }}
    </span>
    <span class="chatter">{{ msg.chatter }}</span>
    <span class="text">{{ msg.text }}</span>
  </p>
</section>
</template>

<script>

export default {
  name: 'ChatLog',
  inject: ['chatLog', 'scrollChatLog'],
  data: function() {
    return {
      chatBox: document.getElementById('chat-log'),
    };
  },
  mounted() {
    this.scrollChatLog()
  },
}
</script>

<style scoped>
.chat-heading {
  padding: 0 1ex;
  display: flex;
  justify-content: space-between;
}

#chat-log {
  color: black;
  text-align: left;
  padding: 0 1ex;
  height: 16.3em;
  overflow-y: scroll;
}
#chat-log span {
    padding: .1rem .2rem;
}
#chat-log span.chatter {
    font-weight: bold;
}
#chat-log span.role {
    color: #292966;
}
#chat-log span.role img {
    width: 52px;
    height: 22px;
}
#chat-log span.chatter:hover {

}

</style>
