<template>
<div id="chat-heading">
  <span>
    Welcome <strong v-if="currentChatname">{{ currentChatname }}</strong>
    <base-button 
      v-if="!signInOn && currentChatname"
      @click="signInOn = true"
      class="flat"
      >(Change Name)</base-button>
    <base-button
      v-else-if="!signInOn"
      @click="signInOn = true"
      class="flat"
      >(Set Name)</base-button>
    <base-dialog v-else @close="closeNameInput" title="Set Name">
      <form @submit.prevent="setNameClose($event)">
        <label for="chatname">{{ yourChatName }}</label>
        <input id="chatname" name="chatname" :value="chatName"/>
        <base-button type="submit">OK</base-button>
      </form>
      <p v-if="notice">{{ notice }}</p>
    </base-dialog>
  </span>
</div>
</template>

<script>
export default {
  props: ['currentChatname'],
  inject: ['setChatName'],
  data() {
    return {
      signInOn: false,
      yourChatName: 'Your chat name',
      notice: "",
    }
  },
  computed: {
    chatName() {
      return this.currentChatname
    }
  },
  methods: {
    setNameClose(ev) {
      //story.match(/\p{L}|\p{N}/gu);
      const val = ev.target.chatname.value
      if (val.includes(' ') || val.includes('#') || val.match(/\p{Z}/u)) {
        this.notice = "Can not contain spaces or # symbol."
      } else if (val.length>12) {
        this.notice = "Can not be over 12 characters."
      } else {
        this.setChatName(val)
        this.closeNameInput()
      }
    },
    closeNameInput() {
      this.signInOn = false
    }
  },
};
</script>

<style scoped>
label {
    padding: .3rem;
}
#chat-heading {
}
</style>
